.register {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.5)), url("../../../public/assests/coverRegister.jpeg");
    background-size: cover;
}

.registerTile {
    font-size: 50px;
    font-family: 'Varela Round', sans-serif;
    font-weight: bold;
}

.registerForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.registerForm>label {
    margin: 10px 0;
    font-family: 'Varela Round', sans-serif;

}

.registerInput {
    padding: 10px;
    background-color: white;
    border: none;
    font-family: 'Varela', sans-serif;
    width: 300px;
    box-shadow: 0 0 5px white;
}

.registerInput:focus {
    outline: none;
    box-shadow: 0 0 5px black;
}

.registerButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: teal;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
    font-family: 'Varela Round', sans-serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 2px;
    box-shadow: 0 0 5px black;
}

.registerLoginButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: lightcoral;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
    width: 120px;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 2px;
    box-shadow: 0 0 5px black;
}