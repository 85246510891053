.singlePost {
    flex: 9;
}

.singlePostWrapper {
    padding: 20px;
    padding-right: 0;
}

.singlePostImg {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
}

.singlePostTitle {
    text-align: center;
    font-family: 'Lora', serif;
    margin: 10px;
    font-size: 30px;
}

.singlePostEdit {
    float: right;
    font-size: 16px;
    margin-top: 5px;

}

.singlePostIcon {
    margin-left: 10px;
    font-size: 25px;
    cursor: pointer;
}

.singlePostIcon:first-child {
    color: teal;
}

.singlePostIcon:last-child {
    color: tomato;
}

.singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: 'Varela Round', sans-serif;
    color: #b39656;
}

.descContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.singlePostDesc {
    width: 90%;
    color: #666;
    font-size: 18px;
    line-height: 25px;
}

.singlePostDesc::first-letter {
    margin-left: 20px;
    font-size: 35px;
    font-weight: 600;
}